import { motion } from "framer-motion";
import images from "../assets/images";
import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";

const ProjectItem = ({ title, image, description }) => {
  return (
    <motion.div
      className="md:w-[calc(50% - 16px)] md:flex-1 md:mt-5 mb-8 mt-2 "
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, duration: 0.5 }}
    >
      <div className="max-w-md rounded-lg shadow">
        <img className="rounded-t-lg" src={image} alt={title} style={{ width: '100%', height: '250px' }} />
        <div className="text-center">
          <span className="block font-bold mt-3">{title}</span>
        </div>
        <div className="h-15">
          <p className="font-normal font-light text-gray-700 dark:text-gray-400" style={{ maxHeight: "300px", padding: "5px" }}>
            {description}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

const Projects = () => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    <section id="services" className="pt-8 background-transparent pb-8">
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-orange-500">Services</span>
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>
        <p className="mt-5 mb-5">
          "GET4GROW transforms restaurants into success stories. We craft innovative menus, offer tailored staff solutions, and deploy strategic marketing to boost visibility. Our thorough audits pinpoint inefficiencies and drive performance. Trust us to prioritize your journey to greatness."
        </p>
      </motion.div>
      <div className="md:flex md:justify-between md:flex-wrap md:-mx-5 gap-5 mx-3 md:w-[calc(50% - 20px)]">
        {/* Menu Development */}
        <ProjectItem
          title="Menu Development"
          image={images.menu}
          description="Crafting a menu that delights diners and aligns with your brand identity and financial objectives is essential. Our team of culinary experts will help conceptualize, design, and refine a menu showcasing your unique culinary offerings while maximizing profitability."
        />

        {/* Restaurant Staff Solutions */}
        <ProjectItem
          title="Restaurant Staff Solutions"
          image={images.staffHiring}
          description="Your team is the heartbeat of your restaurant. Finding the right talent is key to success. From recruitment and training to performance management and retention strategies, we provide comprehensive solutions to maintain a skilled and motivated workforce."
        />

        {/* Restaurant Marketing and Sales Growth */}
        <ProjectItem
          title="Restaurant Marketing and Sales Growth"
          image={images.marketing}
          description="Effective marketing is crucial in today's competitive landscape. Our specialists develop customized strategies to enhance your online presence, drive foot traffic, and boost sales, ensuring your restaurant stands out."
        />

        {/* Restaurant Audits */}
        <ProjectItem
          title="Restaurant Audits"
          image={images.S1}
          description="Operational efficiency is vital. Our audits evaluate every operation, from food quality and service standards to financial performance and compliance. We identify areas for improvement to optimize performance and drive profitability."
        />
      </div>
    </section>
  );
};

export default Projects;
