import S1 from "./S1.jpg";
import brush from "./brush.png";
import closeIcon from "./close-icon.svg";
import contactImage from "./contact-image.jpeg";
import facebook from "./facebook.png";
import instagram from "./instagram.png";
import linkedin from "./linkedin.png";
import logo from "./logo.png";
import marketing from "./marketing.png";
import menuIcon from "./menu-icon.svg";
import menu from "./menu.png";
import profileImage from "./profile-image.png";
import sl2 from "./sl-2.jpeg";
import sl3 from "./sl-3.jpeg";
import sl4 from "./sl-4.jpeg";
import sl5 from "./sl-5.jpeg";
import sl6 from "./sl-6.jpeg";
import about from "./slider.png";
import staffHiring from "./staffhiring.png";
import twitter from "./twitter.png";

const images = {
  S1,
  brush,
  closeIcon,
  contactImage,
  facebook,
  instagram,
  linkedin,
  logo,
  marketing,
  menuIcon,
  menu,
  profileImage,
  sliderImages: [sl2, sl3, sl4, sl5, sl6],
  staffHiring,
  twitter,
  about
};

export default images;
