import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import LineGradient from "../components/LineGradient";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset, 
  } = useForm();


  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);
  
  const onSubmit = (formData) => {
    setLoading(true); 
    emailjs
      .send(
        'service_yb9k4cv',
        'template_is12ikn',
        {
          from_name: formData.name,
          to_name: 'Get4Grow',
          to_email: "get4grow@gmail.com",
          reply_to:formData.email,
          message: formData.message,
        },
        'MO4ueSCfI81_F9965'
      )
      .then(
        () => {
          setLoading(false);
          setIsSubmitted(true); 
          reset(); 
        },
        (error) => {
          setLoading(false); 
          console.error(error);
          alert("Something Went Wrong.");
        }
      );
  };

  return (
    <section id="contact" className="contact py-12">
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="flex justify-end w-full"
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-orange-500">CONTACT US</span> TO GET STARTED
          </p>
          <div className="flex md:justify-end my-5">
            <LineGradient width="w-1/2" />
          </div>
        </div>
      </motion.div>

      <div className="md:flex md:justify-between gap-16 mt-5 ">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 flex justify-center"
        >
          <img src="../assets/contact-image.jpeg" alt="contact" />
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 mt-10 md:mt-0"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              className="w-full bg-white font-semibold placeholder-opaque-black p-3 rounded-md"
              type="text"
              placeholder="NAME"
              {...register("name", {
                required: true,
                maxLength: 100,
              })}
            />
            {errors.name && (
              <p className="text-red mt-1">
                {errors.name.type === "required" && "This field is required."}
                {errors.name.type === "maxLength" &&
                  "Max length is 100 char."}
              </p>
            )}

            <input
              className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-md"
              name="email"
              type="email"
              placeholder="EMAIL"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email && (
              <p className="text-red mt-1">
                {errors.email.type === "required" && "This field is required."}
                {errors.email.type === "pattern" &&
                  "Invalid email address."}
              </p>
            )}

            <textarea
              className="w-full bg-white font-semibold placeholder-opaque-black p-3 mt-5 rounded-md"
              name="message"
              placeholder="MESSAGE"
              rows="4"
              cols="50"
              {...register("message", {
                required: true,
                maxLength: 2000,
              })}
            />
            {errors.message && (
              <p className="text-red mt-1      bg-orang">
                {errors.message.type === "required" &&
                  "This field is required."}
                {errors.message.type === "maxLength" &&
                  "Max length is 2000 char."}
              </p>
            )}

            <button
              className="p-5 bg-gradient-to-r from-orange-300 to-pink-900 font-semibold text-deep-blue mt-5 hover:bg-opaque-black hover:text-white transition duration-500 rounded-md"
              type="submit"
              disabled={loading} 
            >
              {loading ? "Sending..." : "SEND A MESSAGE"}
            </button>
          </form>
          {isSubmitted && (
        <div className="mt-3 bg-green-200 text-green-800 px-4 py-2 rounded-md transition-opacity duration-300 opacity-100">
          Your message has been successfully sent!
        </div>
      )}
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
