import { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import logo from "../assets/logo.png";
import useMediaQuery from "../hooks/useMediaQuery";

const Link = ({ page, selectedPage, setSelectedPage, closeMenu }) => {
  const lowerCasePage = page.toLowerCase();
  const handleClick = () => {
    setSelectedPage(lowerCasePage);
    closeMenu(); 
  };

  return (
    <AnchorLink
      className={`${
        selectedPage === lowerCasePage ? "text-orange-500" : ""
      } hover:text-orange-500 transition duration-500`}
      href={`#${lowerCasePage}`}
      onClick={handleClick}
    >
      {page}
    </AnchorLink>
  );
};

const Navbar = ({ isTopOfPage, selectedPage, setSelectedPage }) => {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const navbarBackground = isTopOfPage ? "top-[5px] mx-auto z-[11]" : " position:fixed z-[120] bg-gradient-to-r from-orange-300 to-pink-900 w-[80%] top-3 mx-auto rounded-full text-white";
  const logoHeight = "3.3rem"; 
  const logoWidth = "30rem"; 

  const closeMenu = () => {
    setIsMenuToggled(false);
  };

  return (
    <nav className={`fixed top-0 left-0 w-[85%] mx-auto right-0 ${navbarBackground}`}>
      <div className="flex items-center justify-between mx-auto w-5/6">
        <img
          src={logo}
          alt="Logo"
          style={{
            margin: 0,
            mixBlendMode: "color-burn",
            backgroundColor: "white",
            maxWidth: logoWidth,
            borderRadius: "85px",
            maxHeight: logoHeight,
            padding: 0,
            opacity:0.9,

          }}
        />
      
        {isDesktop ? (
          <div className="flex justify-between gap-16 font-opensans text-sm font-semibold">
            <Link
              page="Home"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              closeMenu={closeMenu}
            />
            <Link
              page="About"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              closeMenu={closeMenu}
            />
            <Link
              page="Services"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              closeMenu={closeMenu}
            />
            <Link
              page="Contact"
              selectedPage={selectedPage}
              setSelectedPage={setSelectedPage}
              closeMenu={closeMenu}
            />
          </div>
        ) : (
          <button
            className=""
            onClick={() => setIsMenuToggled(!isMenuToggled)}
          >
            <img 
            style={{mixBlendMode:"normal",opacity:2.0}}
            alt="menu-icon" src="../assets/menu-icon.svg" />
          </button>
        )}

        {/* MOBILE MENU POPUP */}
        {!isDesktop && isMenuToggled && (
          <div className="fixed right-0 bottom-0 h-full bg-black rounded-bl-2xl w-[250px]">
            {/* CLOSE ICON */}
            <div className="flex justify-end p-12">
              <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                <img alt="close-icon" src="../assets/close-icon.svg" />
              </button>
            </div>

            {/* MENU ITEMS */}
            <div className="flex flex-col gap-10 ml-[33%] text-2xl text-white">
              <Link
                page="Home"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                closeMenu={closeMenu}
              />
              <Link
                page="About"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                closeMenu={closeMenu}
              />
              <Link
                page="Services"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                closeMenu={closeMenu}
              />
              <Link
                page="Contact"
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                closeMenu={closeMenu}
              />
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
