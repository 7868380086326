import { motion } from "framer-motion";
import images from "../assets/images";
import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";

const MySkills = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="about" className="pt-1 pb-4">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-8">
        <motion.div
          className="md:w-1/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-4xl mb-3">
             <span className="text-orange-500">About </span>Us
          </p>
          <LineGradient width="w-1/3" />
          <p className="mt-10 mb-7">
          Welcome to GET4GROW, where culinary innovation meets strategic brilliance.
We are not just consultants; we are culinary enthusiasts on a mission to revolutionize the dining experience and drive business growth. With a fervent passion for food and a keen eye for business strategy, we stand ready to elevate your restaurant to unprecedented success.
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-10
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
            >
              <img
                alt="skills"
                className="z-10 h-[300px] w-[350px]"
                src={images.about}
              />
            </div>
          ) : (
            <img alt="skills" className="z-10" src={images.about} />
          )}
        </div>
      </div>

      {/* SKILLS */}
      <div className="md:flex md:justify-between  gap-32 ">
        {/* EXPERIENCE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Culinary Innovation:
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute  opacity-50 right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          Experience the thrill of pushing culinary boundaries with our avant-garde dishes and trending ingredients. Let us transform your restaurant into a culinary destination where every dish tells a story.
          </p>
        </motion.div>

        {/* INNOVATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Strategic Brilliance:
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-rose-800   absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          Optimize your restaurant's success with our strategic planning expertise. From analyzing market trends to developing targeted campaigns, we'll craft a customized roadmap for your business.
          </p>
        </motion.div>
        {/* IMAGINATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
              Alliance:
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-indigo-900  absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          Forge strong, trust-based relationships with our collaborative. Together, we'll embark on a journey of innovation, problem-solving, and shared success, fostering lasting partnerships.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default MySkills;
