import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-orange-300 to-pink-900   pt-0.5">
    <div className="w-9/12 mx-auto mb-1 ">
      <SocialMediaIcons />
      <div className="md:flex justify-center md:justify-between text-center">
        <p className="font-playfair font-semibold text-l mb-1  bg-opacity-80  text-white  ">
          GET4GROW
        </p>
        <p className="font-playfair text-xs  bg-opacity-50 text-white ">
          All Rights Reserved.
        </p>
      </div>
    </div>
  </footer>
  
  );
};

export default Footer;
